import React from 'react';
import styled from 'styled-components';

const UnderConstruction = () => {
  return (
    <div>
      <Wrap>
        <h1 style={{ color: '#fff' }}>Heyooo Sorry! There's nothing here yet because...</h1>
        <h1 style={{ color: '#fff' }}>Under Construction!</h1>
      </Wrap>
    </div>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

export default UnderConstruction;
